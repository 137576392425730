import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./style.css";
import { Autoplay, EffectFade, Navigation } from "swiper";
import capelaSlide from "./img/capela/capelaSlide.webp";
import noiva from "./img/lazer/noiva.webp";
import acomodacoesSlide from "./img/acomodacoes/acomodacoesSlide.webp";
import acomodacoesLago from "./img/acomodacoes/acomodacaoLago.webp";
// import acomodacoesCasa from "./img/acomodacoes/acomodacaoCasa.webp";
import eventosSlide from "./img/eventos/eventosSlide.webp";
import piscina from "./img/eventos/piscina.webp";
// import { Button } from "react-bootstrap";
export default function SlidePrincipal() {
  return (
    <div id="slidePrincipal" className="position-relative">
      <Swiper
        slidesPerView={1}
        effect={"fade"}
        spaceBetween={0}
        loop={true}
        navigation={true}
        modules={[Navigation, Autoplay, EffectFade]}
        className="mySwiper"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiperCore) => {
          const { activeIndex, snapIndex, previousIndex, realIndex, slides } =
            swiperCore;
          console.log({ activeIndex, snapIndex, previousIndex, realIndex });
          const index_currentSlide = activeIndex;
          const currentSlide = slides[index_currentSlide];
          for (let i = 0; i < slides.length; i++) {
            slides[i].children[0].classList.remove('zoom-img')
          }
          currentSlide.children[0].classList.add('zoom-img');
          // console.log(slides)
          
        }}
      >
        <SwiperSlide>
          <img src={eventosSlide} alt="Acomodações do hotel" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={capelaSlide} alt="contato com a natureza" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={acomodacoesSlide} alt="Cavalos" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={acomodacoesLago} alt="Pet Friendly" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={piscina} alt="Pet Friendly" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={noiva} alt="Pet Friendly" />
        </SwiperSlide>
      </Swiper>
      {/* <div className="botao_reserve_agora d-flex justify-content-center align-items-center position-absolute w-100">
        <a href="#Reserve">
          <Button variant="light" className="btn-white fw-bold">
            RESERVE AGORA
          </Button>
        </a>
      </div> */}
      <div className="py-0 py-lg-3 bg-pastel borda-SlidePrincial"></div>
    </div>
  );
}
