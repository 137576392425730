import _1366 from "./CAPELA.webp"
import _414 from "./CAPELA-414.webp"
import _768 from "./CAPELA-768.webp"
import _1024 from "./CAPELA-1024.webp"
import _1280 from "./CAPELA-1280.webp"

export default [
    _414 + ' 414w',
    _768 + ' 768w',
    _1024 + ' 1024w',
    _1280 + ' 1280w',
    _1366,
];