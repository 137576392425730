import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import "./style.css";
import { BsPlayCircle } from "react-icons/bs";
import ReactPlayer from "react-player/facebook";
export default function VideoPlayer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="w-100 player_video position-relative">
        <div className="video-full-inner">
          <div className="d-flex justify-content-center flex-column text-center w-100">
            <div className="col-10 col-sm-8 col-lg-6 col-xxl-4 flex-column d-flex justify-content-center text-center mx-auto texto-player-fazenda">
              <span className="fw-bold cor-secondaria">CONHEÇA A</span>
              <h1 className="fontAbhaya cor-fazenda fw-bold">
                FAZENDA SÃO JOAQUIM
              </h1>
            </div>
          </div>
          <Button className="bg-transparent border-0">
            <BsPlayCircle onClick={handleShow} />
          </Button>

          <Offcanvas className="w-100" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Fazenda São Joaquim</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className='streamable'><iframe src="https://streamable.com/e/7fdc3m" frameborder="0" width="100%" height="100%" allowfullscreen></iframe></div>
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://www.veed.io/view/b84f15d0-3449-4a0b-9279-ceda3ad63c5a"
                  className="react-player"
                  playing
                  width="100%"
                  height="70vh"
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
}
