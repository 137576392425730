import _414 from "./mini_wedding-414.webp"
import _768 from "./mini_wedding-768.webp"
import _1024 from "./mini_wedding-1024.webp"
import _1280 from "./mini_wedding-1280.webp"
import _1366 from "./mini_wedding.webp"

export default [
    _414 + ' 414w',
    _768 + ' 768w',
    _1024 + ' 1024w',
    _1280 + ' 1280w',
    _1366,
];