import mini_wedding1 from "./fotos/WEBP/fotos (1).webp";
import mini_wedding2 from "./fotos/WEBP/fotos (2).webp";
import mini_wedding3 from "./fotos/WEBP/fotos (3).webp";
import mini_wedding4 from "./fotos/WEBP/fotos (4).webp";
import mini_wedding5 from "./fotos/WEBP/fotos (5).webp";
import mini_wedding6 from "./fotos/WEBP/fotos (6).webp";
import mini_wedding7 from "./fotos/WEBP/fotos (7).webp";
import mini_wedding8 from "./fotos/WEBP/fotos (8).webp";
import mini_wedding9 from "./fotos/WEBP/fotos (9).webp";
import mini_wedding10 from "./fotos/WEBP/fotos (10).webp";
import mini_wedding11 from "./fotos/WEBP/fotos (11).webp";
import mini_wedding12 from "./fotos/WEBP/fotos (12).webp";
import mini_wedding13 from "./fotos/WEBP/fotos (13).webp";
import mini_wedding14 from "./fotos/WEBP/fotos (14).webp";
import mini_wedding15 from "./fotos/WEBP/fotos (15).webp";
import mini_wedding16 from "./fotos/WEBP/fotos (16).webp";
import mini_wedding17 from "./fotos/WEBP/fotos (17).webp";
import mini_wedding18 from "./fotos/WEBP/fotos (18).webp";
import mini_wedding19 from "./fotos/WEBP/fotos (19).webp";
import mini_wedding20 from "./fotos/WEBP/fotos (20).webp";
import mini_wedding21 from "./fotos/WEBP/fotos (21).webp";
import mini_wedding22 from "./fotos/WEBP/fotos (22).webp";
import mini_wedding23 from "./fotos/WEBP/fotos (23).webp";

export default [
  mini_wedding1,
  mini_wedding2,
  mini_wedding3,
  mini_wedding4,
  mini_wedding5,
  mini_wedding6,
  mini_wedding7,
  mini_wedding8,
  mini_wedding9,
  mini_wedding10,
  mini_wedding11,
  mini_wedding12,
  mini_wedding13,
  mini_wedding14,
  mini_wedding15,
  mini_wedding16,
  mini_wedding17,
  mini_wedding18,
  mini_wedding19,
  mini_wedding20,
  mini_wedding21,
  mini_wedding22,
  mini_wedding23,
];
