import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Lazy } from "swiper";
import { Modal } from "react-bootstrap";

import capela from "./img/capela/capa";
import coqueteis from "./img/mini_wedding/capa";
import ensaiosFotograficos from "./img/ensaiosFotograficos/capa";
import Fade from "react-reveal/Fade";
import fotos_capela from "./img/capela";
import ensaios from "./img/ensaiosFotograficos";
import fotos_mini_wedding from "./img/mini_wedding";

import "./style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/lazy";

export default function Atividades() {
  const arr = [
    {
      imagem: coqueteis,
      texto: "EVENTOS",
      fotos: fotos_mini_wedding,
    },
    {
      imagem: capela,
      texto: "A FAZENDA",
      fotos: fotos_capela,
    },
    
    {
      imagem: ensaiosFotograficos,
      texto: "ENSAIOS FOTOGRAFICOS",
      fotos: ensaios,
    },
  ];
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [modalData, setModalData] = useState(null);

  function handleShow(breakpoint, texto) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <Container>
      <div id="atividades" className="position-relative">
        <Fade bottom distance="30px">
          <div className="slide_atividades position-relative">
            <Swiper
              style={{
                "--swiper-navigation-color": "#5a3722",
                "--swiper-pagination-color": "#5a3722",
              }}
              slidesPerView={3}
              spaceBetween={0}
              slidesPerGroup={1}
              loop={false}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {arr.map(function (element, index) {
                return (
                  <SwiperSlide key={index}>
                    <Card className="mx-auto shadow">
                      <Card.Img
                        variant="top"
                        src={element["imagem"][0]}
                        srcSet={`${element["imagem"].toString()} 1366w`}
                      />
                      <Card.Body>
                        <Card.Title className="fontAbhaya">
                          {element["texto"]}
                        </Card.Title>
                        <div className="pt-3">
                          <button
                            onClick={() => {
                              handleShow(true, element["texto"]);
                              setModalData(element);
                            }}
                            className="btn btn-fazenda w-75"
                          >
                            VER ESPAÇO
                          </button>
                          <Modal
                            show={show}
                            fullscreen={fullscreen}
                            onHide={() => setShow(false)}
                          >
                            {modalData != null ? (
                              <>
                                <Modal.Header closeButton>
                                  <Modal.Title>{modalData.texto}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className="card-slide row justify-content-center">
                                    <div className="col-12 col-xl-12">
                                      <Swiper
                                        style={{
                                          "--swiper-navigation-color": "#fff",
                                          "--swiper-pagination-color": "#fff",
                                        }}
                                        lazy={true}
                                        pagination={{
                                          clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Lazy, Pagination, Navigation]}
                                        className="slideCard"
                                      >
                                        {modalData.fotos.map((e, i) => (
                                          <SwiperSlide key={i}>
                                            <img
                                              data-src={e}
                                              alt=""
                                              className="swiper-lazy"
                                            />
                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                                          </SwiperSlide>
                                        ))}
                                      </Swiper>
                                    </div>
                                  </div>
                                </Modal.Body>
                              </>
                            ) : (
                              ""
                            )}
                          </Modal>
                        </div>
                      </Card.Body>
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Fade>
      </div>
    </Container>
  );
}
