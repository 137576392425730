import { useEffect } from 'react';
import Atividades from '../../components/Atividades';
import Estrutura from '../../components/Estrutura';
import Header from "../../components/Header";
import Main from '../../components/Main';
import Realizacoes from '../../components/Realizacoes';
import SlidePrincipal from "../../components/SlidePrincipal";
import Sobre from "../../components/Sobre";
import VideoPlayer from '../../components/VideoPlayer';

export default function Home() {
  useEffect(() => {
    document.title = "Fazenda São Joaquim"
    document.querySelector('meta[name="description"]').setAttribute("content", 'Fazenda São Joaquim foi idealizado para proporcinar um contato intenso com a natureza. Aqui, oferecemos um autentico convivio com a lida do campo e, através de gastronomia, tradição e história apresentamos o que a cultura gaúcha tem de melhor.');
 }, []);
  return (
    <div className='position-relative body'>
      <Header>
        <SlidePrincipal />
      </Header>
      <Main>
        <Sobre>
          <Atividades />
        </Sobre>
        <Estrutura />
        <VideoPlayer />
        <Realizacoes />
      </Main>
    </div>
  );
}
