import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import "./fonts/fonts.css";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import Cookies from "./pages/Cookies";
import Cabecalho from "./components/Cabecalho";
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";
import ErrorPage from './pages/404';
function App() {
  return (
    <BrowserRouter>
      <Cabecalho />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/politica-de-privacidade"
          element={<PoliticaPrivacidade />}
        />
        <Route path="/cookies" element={<Cookies />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        cookieName="cookies_fazenda_sao_joaquim"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#5a3722", fontSize: "13px" }}
        expires={365}
      >
        Este site usa cookies para melhorar a experiência do usuário
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
