import _414 from "./ENSAIOSFOTOGRAFICOS-414.webp"
import _768 from "./ENSAIOSFOTOGRAFICOS-768.webp"
import _1024 from "./ENSAIOSFOTOGRAFICOS-1024.webp"
import _1280 from "./ENSAIOSFOTOGRAFICOS-1280.webp"
import _1366 from "./ENSAIOSFOTOGRAFICOS.webp"

export default [
    _414 + ' 414w',
    _768 + ' 768w',
    _1024 + ' 1024w',
    _1280 + ' 1280w',
    _1366,
];