import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import FacebookIcon from "../svg/Facebook";
import "./style.css";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logoFazenda from "../../img/logo/fazendaSaoJoaquim/logo.webp";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
export default function Cabecalho() {
   
  return (
    <header>
      <div className="site-header header-over">
        <div className="header-top">
          <div className="container-lg container-fluid wrapper">
            <Navbar className="pb-0">
              <Container fluid="lg">
                <Navbar.Text href="#home">MÍDIAS SOCIAIS</Navbar.Text>
                <Nav className="me-auto">
                  <Nav.Link href="https://www.facebook.com/Itu.FazendaSaoJoaquim" className="d-flex">
                    <FacebookIcon />
                  </Nav.Link>
                  <Nav.Link href="https://www.instagram.com/fazenda.saojoaquim/" className="d-flex">
                    <BsInstagram />
                  </Nav.Link>
                </Nav>
                <Navbar.Toggle />
                <Navbar className="justify-content-end">
                  <Nav className="ms-auto">
                    <Nav.Link href="https://api.whatsapp.com/send?phone=5511985971039&text=Vim através do site da Fazenda São Joaquim." className="d-flex align-items-center">
                      <BsWhatsapp /> &nbsp; 11 9 8597-1039
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </Container>
            </Navbar>
          </div>
        </div>
        <div className="header-bottom">
          <div className="container wrapper">
            <div className="header-logo">
              <Link to={"/"} className='d-block'>
                <img src={logoFazenda} alt="Fazenda São Joaquim" width={220} height={121} />
              </Link>
            </div>

            <Navbar key="lg" expand="lg" className="mb-3">
              <Container fluid>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-lg`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                  placement="end"
                  className="w-100"
                >
                  <Offcanvas.Header
                    id="menu_fazenda_titulo"
                    className="bg-menu"
                    closeButton
                  >
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-lg`}
                      className="text-center w-100"
                    >
                      <img src={logoFazenda} alt="Fazenda São Joaquim" width={200} height={121} />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="bg-menu" id="menu_fazenda">
                    <Nav className="me-auto">
                      <Nav.Link
                      as={HashLink} smooth to="/#A_Fazenda"
                        className="d-flex align-items-center ps-0"
                      >
                        A FAZENDA
                      </Nav.Link>
                      <Nav.Link as={HashLink} smooth to="/#acomodacoes" className="d-flex align-items-center">
                        ACOMODAÇÕES
                      </Nav.Link>
                      <Nav.Link as={HashLink} smooth to="/#atividades" className="d-flex align-items-center">
                        SERVIÇOS
                      </Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                      <Nav.Link
                        href="/#atividades"
                        className="d-flex align-items-center ps-0"
                      >
                        ATIVIDADES
                      </Nav.Link>
                      <Nav.Link as={HashLink} smooth to="/#atividades" className="d-flex align-items-center">
                        PET FRIENDLY
                      </Nav.Link>
                      <Nav.Link as={HashLink} smooth to="/#contato" className="d-flex align-items-center">
                        CONTATO
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                  <div className="offcanvas-footer d-md-none bg-menu text-center">
                    <a href="/#Reserve">
                      <Button variant="light" className="btn-white fw-bold">
                        RESERVE AGORA
                      </Button>
                    </a>
                  </div>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </header>
    
  );
}
