import foto1 from "./fotos/fotos (1).webp"
import foto2 from "./fotos/fotos (2).webp"
import foto3 from "./fotos/fotos (3).webp"
import foto4 from "./fotos/fotos (4).webp"
import foto5 from "./fotos/fotos (5).webp"
import foto6 from "./fotos/fotos (6).webp"
import foto7 from "./fotos/fotos (7).webp"
import foto8 from "./fotos/fotos (8).webp"
import foto9 from "./fotos/fotos (9).webp"
import foto10 from "./fotos/fotos (10).webp"

export default [
    foto1,
    foto2,
    foto3,
    foto4,
    foto5,
    foto6,
    foto7,
    foto8,
    foto9,
    foto10
];